<template>
  <v-container>
    <header class="custom-header mb-6">
      <h5>සාමාජික අංකය : {{member.memberId.value}}</h5>
    </header>

    <v-card class="custom-shadow">
      <v-tabs @change="changeTab" background-color="transparent" v-model="tab">
        <v-tab>සාමාජිකයාගේ විස්තර</v-tab>
        <v-tab>පින්තූර</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card class="mt-3" flat>
            <v-card-text>
              <v-row no-gutters>
                <v-col :key="index" cols="12" md="6" v-for="(obj, index) in member">
                  <v-row no-gutters>
                    <v-col sm="5">
                      <h6 class="font-weight-medium">{{obj.label}}</h6>
                    </v-col>
                    <v-col sm="7">
                      <h6 class="font-weight-bold">: {{obj.value}}</h6>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col :key="index" cols="12" md="6" v-for="(obj, index) in horoscopeDetails">
                  <v-row no-gutters>
                    <v-col sm="5">
                      <h6 class="font-weight-medium">{{obj.label}}</h6>
                    </v-col>
                    <v-col sm="7">
                      <h6 class="font-weight-bold">: {{obj.value}}</h6>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row :key="index" class="mt-4" no-gutters v-for="(obj, index) in extraDetails">
                <v-col cols="12">
                  <h6 class="font-weight-medium">{{obj.label}}</h6>
                </v-col>
                <v-col cols="12">
                  <h6 class="font-weight-bold">{{obj.value}}</h6>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="mt-3" flat>
            <v-card-text>
              <v-row justify="center" v-if="loadingTab2">
                <v-progress-circular color="accent" indeterminate></v-progress-circular>
              </v-row>
              <v-row v-else>
                <v-col sm="4">
                  <v-img
                    :src="profilePicture"
                    contain
                    height="300"
                    lazy-src="../../assets/images/not_found.png"
                  ></v-img>
                  <p class="text-center my-1">පැතිකඩ පින්තූරය</p>
                </v-col>
                <v-col sm="4">
                  <v-img
                    :src="nicFront"
                    contain
                    height="300"
                    lazy-src="../../assets/images/not_found.png"
                  ></v-img>
                  <p class="text-center my-1">ජාතික හැඳුනුම්පත(ඉදිරිපස)</p>
                </v-col>
                <v-col sm="4">
                  <v-img
                    :src="nicBack"
                    contain
                    height="300"
                    lazy-src="../../assets/images/not_found.png"
                  ></v-img>
                  <p class="text-center my-1">ජාතික හැඳුනුම්පත(පිටුපස)</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { ApiService } from "../../services/api.service";
import { CHANGE_LOADING_STATE, SHOW_ALERT } from "../../store/mutation-types";
import ConfirmDialog from "../../components/ConfirmDialog";

export default {
  components: {
    ConfirmDialog
  },

  data: () => ({
    tab: 0,
    tab2Opened: false,
    loadingTab2: false,
    profilePictureFilename: "",
    profilePicture: "",
    nicFrontFilename: "",
    nicFront: "",
    nicBackFilename: "",
    nicBack: "",
    member: {
      memberId: { label: "සාමාජික අංකය", value: "" },
      gender: { label: "ස්ත්‍රී/පුරුෂ", value: "" },
      age: { label: "වයස", value: "" },
      dob: { label: "උපන් දිනය", value: "" },
      livingCountry: { label: "ජීවත්ව සිටින රට", value: "" },
      province: { label: "පළාත", value: "" },
      district: { label: "දිස්ත්‍රික්කය", value: "" },
      town: { label: "නගරය", value: "" },
      race: { label: "ජාතිය", value: "" },
      religion: { label: "ආගම", value: "" },
      cast: { label: "කුළය", value: "" },
      marriageStatus: { label: "විවාහක තත්වය", value: "" },
      numberOfChildren: { label: "දරුවන් සංඛ්‍යාව", value: "" },
      respOfChildren: { label: "දරුවන්ගේ වගකීම්", value: "" },
      educationLevel: { label: "අධ්‍යාපන මට්ටම", value: "" },
      job: { label: "රැකියා අංශය", value: "" },
      designation: { label: "තනතුර", value: "" },
      height: { label: "උස", value: "" },
      monthlyIncome: { label: "මාසික ආදායම", value: "" },
      incomeValue: { label: "මාසික ආදායම (අගය)", value: "" },
      skinColor: { label: "සමෙහි වර්ණය", value: "" },
      bodyShape: { label: "ශරීර හැඩය", value: "" },
      brothersCount: { label: "සහෝදරයන් සංඛ්‍යාව", value: "" },
      sistersCount: { label: "සහෝදරියන් සංඛ්‍යාව", value: "" },
      assets: { label: "වත්කම්", value: "" },
      // assetsDetails: { label: "වත්කම් විස්තර", value: "" },
      // otherDetails: { label: "වෙනත් විස්තර", value: "" },
      fullName: { label: "සම්පූර්ණ නම", value: "" },
      address: { label: "ලිපිනය", value: "" },
      mobileNumber: { label: "දුරකථන අංකය", value: "" },
      secondaryMobileNumber: { label: "දුරකථන අංකය (වෙනත්)", value: "" },
      nic: { label: "ජාතික හැඳුනුම්පත් අංකය", value: "" },
    },
    horoscopeDetails: {
      birthCountry: { label: "උපන් රට", value: "" },
      birthPlace: { label: "උපන් ස්ථානය", value: "" },
      birthTime: { label: "උපන් වේලාව", value: "" },
      lagna: { label: "ලග්නය", value: "" },
      ganaya: { label: "ගණය", value: "" },
      nekatha: { label: "නැකත", value: "" },
      papaKendara: { label: "පාප කේන්දර", value: "" },
      ravi: { label: "රවි", value: "" },
      chandra: { label: "චන්ද්‍ර", value: "" },
      kuja: { label: "කුජ", value: "" },
      budha: { label: "බුධ", value: "" },
      guru: { label: "ගුරු", value: "" },
      rahu: { label: "රාහු", value: "" },
      sikuru: { label: "සිකුරු", value: "" },
      shani: { label: "ශනි", value: "" },
      kethu: { label: "කේතු", value: "" },
    },
    extraDetails: {
      assetsDetails: { label: "වත්කම් විස්තර", value: "" },
      otherDetails: { label: "වෙනත් විස්තර", value: "" },
    },
  }),

  mounted: async function () {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    await this.fetchMember();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    fetchMember: async function () {
      try {
        const url = "customers/" + this.$route.params.id;
        const response = await ApiService.get(url);

        this.profilePictureFilename = response.data.profilePicture;
        this.nicFrontFilename = response.data.nicFrontPhoto;
        this.nicBackFilename = response.data.nicBackPhoto;
        this.member.memberId.value = response.data.id;
        this.member.gender.value = response.data.gender;
        this.member.age.value = response.data.age;
        this.member.dob.value = response.data.dob;
        this.member.livingCountry.value = response.data.livingCountry;
        this.member.province.value = response.data.province;
        this.member.district.value = response.data.district;
        this.member.town.value = response.data.town;
        this.member.race.value = response.data.race;
        this.member.religion.value = response.data.religion;
        this.member.cast.value = response.data.cast;
        this.member.marriageStatus.value = response.data.marriageStatus;

        if (response.data.marriageStatus == "දික්කසාද" || response.data.marriageStatus == "වැන්ඳඹු") {
          this.member.numberOfChildren.value = response.data.numberOfChildren;
          this.member.respOfChildren.value = response.data.respOfChildren ? "ඇත" : "නැත";
        }

        this.member.educationLevel.value = response.data.educationLevel;
        this.member.job.value = response.data.job;
        this.member.designation.value = response.data.designation;
        this.member.height.value = response.data.height;
        this.member.monthlyIncome.value = response.data.monthlyIncome;
        this.member.incomeValue.value = response.data.incomeValue;
        this.member.skinColor.value = response.data.skinColor;
        this.member.bodyShape.value = response.data.bodyShape;
        this.member.brothersCount.value = response.data.brothersCount;
        this.member.sistersCount.value = response.data.sistersCount;
        this.member.assets.value = response.data.assets ? "ඇත" : "නැත";

        if (response.data.assets) {
          this.extraDetails.assetsDetails.value = response.data.assetsDetails;
        }

        this.extraDetails.otherDetails.value = response.data.otherDetails;
        this.member.fullName.value = response.data.fullName;
        this.member.address.value = response.data.address;
        this.member.mobileNumber.value = response.data.mobileNumber;
        this.member.secondaryMobileNumber.value = response.data.secondaryMobileNumber;
        this.member.nic.value = response.data.nic;
        this.horoscopeDetails.birthCountry.value = response.data.birthCountry;
        this.horoscopeDetails.birthPlace.value = response.data.birthPlace;
        this.horoscopeDetails.birthTime.value = response.data.birthTime;
        this.horoscopeDetails.lagna.value = response.data.lagna;
        this.horoscopeDetails.ganaya.value = response.data.ganaya;
        this.horoscopeDetails.nekatha.value = response.data.nekatha;
        this.horoscopeDetails.papaKendara.value = response.data.papaKendara;
        this.horoscopeDetails.ravi.value = response.data.ravi;
        this.horoscopeDetails.chandra.value = response.data.chandra;
        this.horoscopeDetails.kuja.value = response.data.kuja;
        this.horoscopeDetails.budha.value = response.data.budha;
        this.horoscopeDetails.guru.value = response.data.guru;
        this.horoscopeDetails.rahu.value = response.data.rahu;
        this.horoscopeDetails.sikuru.value = response.data.sikuru;
        this.horoscopeDetails.shani.value = response.data.shani;
        this.horoscopeDetails.kethu.value = response.data.kethu;
      } catch (error) {
        console.log(error);
      }
    },

    // Triggered when tab change
    changeTab: function (number) {
      switch (number) {
        case 1:
          if (!this.tab2Opened) {
            this.getImages();
          }
          break;
        default:
          break;
      }
    },

    getImages: async function () {
      this.loadingTab2 = true;
      this.tab2Opened = true;
      try {
        const url = "files/download";
        const params = {
          params: {
            profile: this.profilePictureFilename, nicFront: this.nicFrontFilename, nicBack: this.nicBackFilename
          }
        };
        const response = await ApiService.get(url, params);

        this.profilePicture = response.data[0]
          ? process.env.VUE_APP_BASE_URL + response.data[0]
          : require("../../assets/images/not_found.png");
        this.nicFront = response.data[1]
          ? process.env.VUE_APP_BASE_URL + response.data[1]
          : require("../../assets/images/not_found.png");
        this.nicBack = response.data[2]
          ? process.env.VUE_APP_BASE_URL + response.data[2]
          : require("../../assets/images/not_found.png");
      } catch (error) {
        console.log(error);
      }
      this.loadingTab2 = false;
    }
  }
};
</script>